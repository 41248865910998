@import 'src/styles/helpers';

@mixin sectionPaddings {
  padding-bottom: 64px;
  @include media(portrait) {
    padding-bottom: 72px;
  }

  @include media(landscape) {
    padding-bottom: 98px;
  }

  @include media(desktopSmall) {
    padding-bottom: 104px;
  }

  @include media(desktop) {
    padding-bottom: 120px;
  }

  @include media(desktopBig) {
    padding-bottom: 144px;
  }

  @include media(desktopHuge) {
    padding-bottom: 164px;
  }
}

.blog {
  @include pageHeaderPadding;
  margin-top: 40px;

  @include media(tablet) {
    margin-top: 52px;
  }

  @include media(desktopAll) {
    margin-top: 0;
  }

  @include media(desktopHuge) {
    margin-top: 98px;
  }

  .hero {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media(tablet) {
      padding-top: 52px;
    }

    @include media(desktopAll) {
      padding-top: 0;
    }

    @include media(desktopBig) {
      padding-bottom: 64px;
    }

    @include media(desktop) {
      padding-bottom: 64px;
    }

    @include media(desktopSmall) {
      padding-bottom: 52px;
    }

    @include media(tablet) {
      padding-bottom: 52px;
    }

    @include media(desktopHuge) {
      padding-top: 98px;
      padding-bottom: 200px;
    }
  }

  @include media(mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bannerAuthor {
    margin-bottom: 24px;

    @include media(tablet) {
      margin-bottom: 32px;
    }

    @include media(desktopAll) {
      margin-bottom: 32px;
    }

    @include media(desktopHuge) {
      margin-bottom: 40px;
    }
  }

  &__posts {
    padding-top: 40px;

    @include media(tablet) {
      padding-top: 52px;
    }

    @include media(landscape) {
      padding-top: 64px;
    }

    @include media(desktopSmall) {
      padding-top: 52px;
      grid-template-columns: 1fr 324px;
      gap: 64px;
    }

    @include media(desktop) {
      padding-top: 64px;
      grid-template-columns: 1fr 374px;
      gap: 104px;
    }

    @include media(desktopBig) {
      padding-top: 64px;
      grid-template-columns: 1fr 374px;
      gap: 120px;
    }

    @include media(desktopHuge) {
      padding-top: 200px;
      grid-template-columns: 1fr 424px;
      gap: 120px;
    }

    @include media(desktopAll) {
      display: grid;
      align-items: flex-start;
    }
  }

  &__sidebar {
    @include media(desktopAll) {
      position: sticky;
      top: var(--margin-sm);
    }
  }

  &__mainList {
    padding-bottom: 72px;

    @include media(landscape) {
      padding-bottom: 104px;
    }

    @include media(desktopAll) {
      padding-bottom: 0;
    }

  }

  &__mainListCard:not(:last-of-type) {
    margin-bottom: 40px;

    @include media(desktop) {
      margin-bottom: 48px;
    }

    @include media(desktopBig) {
      margin-bottom: 48px;
    }

    @include media(desktopHuge) {
      margin-bottom: 64px;
    }
  }

  &__categories {
    margin-bottom: 48px;
  }

  &__popularTopics {
    @include sectionPaddings;
    padding-bottom: 0 !important;
  }

  &__popularTopicsTitle {
    padding-bottom: 24px;
  }

  &__chipsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 14px 8px;
  }
}

.bannerArticle {

  &__headline {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media(tablet) {
      align-items: flex-start;
    }

    @include media(desktopAll) {
      align-items: flex-start;
    }

    @include media(desktopHuge) {
      align-items: flex-start;
    }
  }

  &__title {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-family: 'KharkivTone', sans-serif;
    font-size: 32px;
    line-height: 40px;
    max-width: 100%;
    text-align: center;
    margin-bottom: 40px;
    transition: color 0.3s ease;

    a {
      display: block;
      color: inherit;
    }

    &:hover {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }

    mark {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }

    @include media(portrait) {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 48px;
    }

    @include media(landscape) {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 52px;
    }

    @include media(tablet) {
      text-align: left;
    }

    @include media(desktopSmall) {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 48px;
    }

    @include media(desktop) {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 56px;
    }

    @include media(desktopBig) {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 52px;
    }

    @include media(desktopHuge) {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 80px;
    }

    @include media(desktopAll) {
      text-align: left;
    }
  }

  &__image {
    display: block;
    position: relative;
    height: 140px;
    width: calc(100% + var(--margin-md) * 2);
    @include imgCover;
    @include scaleImg;

    @include media(mobile) {
      margin-left: calc(var(--margin-md) * -1);
    }

    @include media(tablet) {
      width: 100%;
    }

    @include media(portrait) {
      height: 240px;
    }

    @include media(landscape) {
      height: 330px;
    }

    @include media(desktopAll) {
      width: 100%;
    }

    @include media(desktopSmall) {
      height: 390px;
    }

    @include media(desktop) {
      height: 390px;
    }

    @include media(desktopBig) {
      height: 480px;
    }

    @include media(desktopHuge) {
      height: 660px;
    }
  }
}

.exploreTrending {
  position: relative;
  margin-top: 72px;

  @include media(landscape) {
    margin-top: 104px;
  }

  @include media(desktopAll) {
    margin-top: 104px;
  }

  @include media(desktopBig) {
    margin-top: 120px;
  }

  @include media(desktopHuge) {
    margin-top: 200px;
  }

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

.trendingArticles {
  display: grid;
  grid-template-columns: repeat(4, 311px);
  grid-auto-flow: row;
  column-gap: 24px;
  grid-template-areas: "First Second Third Fourth";
  @include scrollOutOfContainer;
  padding-bottom: 72px;
  @include hideScrollbar();

  @include media(portrait) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px 32px;
    grid-template-areas:
      "First First"
      "First First"
      "Second Second"
      "Third Fourth";
  }
  @include media(landscape) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    gap: 24px;
    grid-template-areas:
      "First First Second Second"
      "First First Third Fourth";
    padding-bottom: 104px;
  }

  @include media(desktopAll) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    gap: 24px;
    grid-template-areas:
      "First First Second Second"
      "First First Third Fourth";
    padding-bottom: 104px;
  }

  @include media(desktopBig) {
    gap: 40px;
    padding-bottom: 120px;
  }

  @include media(desktopHuge) {
    gap: 24px 48px;
    padding-bottom: 120px;
  }
}

.trendingArticle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;

  @include media(desktopBig) {
    gap: 32px;
  }
  @include media(desktopHuge) {
    gap: 32px;
  }

  &:nth-child(1) {
    grid-area: First;
  }

  &:nth-child(2) {
    grid-area: Second;
  }

  &:nth-child(3) {
    grid-area: Third;
  }

  &:nth-child(4) {
    grid-area: Fourth;
  }
}

.exploreTrendingHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @include media(portrait) {
    margin-bottom: 40px;
  }

  @include media(landscape) {
    margin-bottom: 48px;
  }

  @include media(desktopAll) {
    margin-bottom: 48px;
  }
}

.exploreTrendingTitle {
  font-size: 20px;
  font-family: 'KharkivTone', sans-serif;
  line-height: 32px;
  text-transform: uppercase;

  @include media(tablet) {
    font-size: 20px;
    line-height: 32px;
  }

  @include media(desktopSmall) {
    font-size: 40px;
    line-height: 52px;
  }

  @include media(desktop) {
    font-size: 40px;
    line-height: 52px;
  }

  @include media(desktopBig) {
    font-size: 40px;
    line-height: 52px;
  }

  @include media(desktopHuge) {
    font-size: 56px;
    line-height: 64px;
    letter-spacing: 1px;
  }
}

.trendingCardTitle {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-size: 16px;
  font-family: 'KharkivTone', sans-serif;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: color 0.3s ease;

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  @include media(tablet) {
    font-size: 20px;
    line-height: 32px;
  }
  @include media(desktop) {
    font-size: 20px;
  }
  @include media(desktopSmall) {
    font-size: 20px;
  }
  @include media(desktopBig) {
    font-size: 24px;
  }
  @include media(desktopHuge) {
    font-size: 24px;
  }
  @include media(desktopAll) {
    line-height: 32px;
  }
}

.trendingCardImage {
  width: 100%;
  height: 300px;
  position: relative;
  @include imgCover;
  @include scaleImg;

  @include media(portrait) {
    height: 224px;
  }
  @include media(landscape) {
    height: 165px;
  }
  @include media(desktopSmall) {
    height: 224.5px;
  }
  @include media(desktop) {
    height: 218.5px;
  }
  @include media(desktopBig) {
    height: 213px;
  }
  @include media(desktopHuge) {
    height: 334px;
  }

  &.bigOne {
    @include media(portrait) {
      height: 545px;
    }
    @include media(landscape) {
      height: 434px;
    }
    @include media(desktopSmall) {
      height: 553px;
    }
    @include media(desktop) {
      height: 541px;
    }
    @include media(desktopBig) {
      height: 562px;
    }
    @include media(desktopHuge) {
      height: 788px;
    }
  }
}

.loadMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin-top: var(--margin-xl);
  font-family: 'KharkivTone', sans-serif;
  height: 48px;

  @keyframes spin {
    from {
      transform: rotate(0)
    }
    to {
      transform: rotate(360deg)
    }
  }

  img {
    animation: spin 1s infinite linear;
  }

  @include media(desktop) {
    margin-top: 56px;
  }

  @include media(desktopBig) {
    margin-top: 56px;
  }

  @include media(desktopHuge) {
    margin-top: 72px;
  }
}
