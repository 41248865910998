@import 'src/styles/helpers';

.case {
  display: grid;
  gap: 24px;
  align-items: flex-start;

  &:not(.case_searchView):not(.case_casesView):nth-child(even) {

    @include media(landscape) {
      .image {
        order: 1;
      }
    }

    @include media(desktopAll) {
      .image {
        order: 1;
      }
    }
  }

  @include media(portrait) {
    gap: 24px;
  }

  @include media(landscape) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: stretch;

    &:not(.case_searchView):not(.case_casesView) {
      gap: 64px;
    }
  }

  @include media(desktopAll) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: stretch;
    gap: 64px;

    &:not(.case_searchView):not(.case_casesView) {
      gap: 64px;
    }
  }

  .image {
    align-content: center;

    img {
      transition: transform .3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  .NDAOverlay {
    display: none;
    position: absolute;
    top: -10px;
    bottom: 0;
    width: 100%;
    height: calc(100% + 20px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 6px;
    padding: 0 10px;
    background-color: rgba(0, 0, 0, 0.29);
    backdrop-filter: blur(16px);
    z-index: 1;

    @include media(notMobile) {
      gap: 16px;
      padding: 0;
    }

    .NDATitle {
      max-width: 322px;
      color: var(--secondary-50);
    }

    .NDADescription {
      max-width: 322px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
      color: var(--secondary-50);
    }

    .NDAButton {
      margin-top: 16px;
      max-width: 322px;
      align-content: center;
      color: var(--primary-500);
      border-color: var(--primary-500);
      
      &:hover {
        background: var(--secondary-700);
      }

      @include media(mobile) {
        max-width: 300px;
        font-size: 14px;
        margin-top: 0;
        padding: 5px 12px 4px;
        height: 38px;
      }
    }
  }

  .infoHeader {

    &.isNDA {
      pointer-events: none;
    }

    &:hover {
      &:not(.isNDA) {

        .title {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  &.NDAView {
    &:hover {
      .NDAOverlay {
        display: flex;
      }
    }
  }

  &_searchView {
    height: 100%;
    gap: var(--margin-sm);

    @include media(tablet) {
      gap: var(--margin-xl);
      grid-template-columns: 260px 1fr;
      align-items: center;
    }

    @include media(desktopAll) {
      gap: var(--margin-xl);
      grid-template-columns: 260px 1fr;
      align-items: center;
    }

    .image {
      min-height: 168px;
      height: 168px;
      overflow: hidden;

      img {
        transition: all 0.3s ease;
      }
    }

    .content {
      padding: 0;
      gap: var(--margin-sm);

      @include media(tablet) {
        padding: var(--margin-sm) 0;
      }

      @include media(desktopAll) {
        padding: var(--margin-sm) 0;
      }
    }

    .title,
    .description {
      color: var(--secondary-200);
      transition: all 0.3s ease;
    }

    .title {
      font-size: 20px;
      line-height: 28px;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      @include media(desktopAll) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .projectName {
      @include setProperty(color, var(--primary-900), var(--primary-500));
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 12px;
      transition: all 0.3s ease;

      @include media(tablet) {
        font-size: 16px;
      }

      @include media(desktopAll) {
        font-size: 16px;
      }
    }

    &:hover {

      .image img {
        transform: scale(1.05);
      }

      .title,
      .description {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
      }

      .projectName {
        @include setProperty(color, var(--primary-500), var(--primary-900));
      }
    }
  }

  &_casesView {
    .image {
      position: relative;
      width: 327px;
      align-content: center;
      height: 100%;
      justify-self: center;

      @include media(mobileSmall) {
        width: 287px;
      }
  

      .imgWrapper {
        margin: auto;
        position: relative;
        aspect-ratio: 3 / 2;
      }

      img {
        height: auto !important;
      }

      @include media(portrait) {
        width: 600px;
      }

      @include media(landscape) {
        order: 1;
        width: 440px;
      }

      @include media(desktopAll) {
        order: 1;
      }

      @include media(desktopSmall) {
        width: 600px;
      }

      @include media(desktop) {
        width: 600px;
      }

      @include media(desktopBig) {
        width: 840px;
      }

      @include media(desktopHuge) {
        width: 840px;
      }
    }

    .content {
      padding: 24px 0;

      .caseInfo {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .projectName {
        margin-bottom: 12px;
      }

      .title {
        font-size: 24px;
        line-height: 32px;
      }

      .description {
        display: block;
        padding: 0;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }

      .technologies {
        padding: 8px 0;
        &__title {
          display: none;
        }
      }

    }

    @include media(landscape) {
      gap: 48px;
      grid-template-columns: 392px auto;
    }

    @include media(desktopAll) {
      gap: 48px;
      grid-template-columns: 392px auto;
    }
  }
}

.image {
  display: block;
  @include imgCover;
  height: 327px;
  overflow: hidden;
  position: relative;

  @include media(portrait) {
    height: 388px;
  }

  @include media(landscape) {
    height: 100%;
  }

  @include media(desktopAll) {
    height: 100%;
  }
}

.content {
  padding-bottom: 40px;
  display: grid;
  gap: 24px;

  @include media(portrait) {
    padding-bottom: 24px;
  }

  @include media(landscape) {
    padding: 24px 0;
  }

  @include media(desktopAll) {
    padding: 24px 0;
  }
}

.projectName {
  @include setProperty(color, var(--primary-500), var(--primary-900));
  font-size: 16px;
  line-height: 20px;
  letter-spacing: var(--ls-183);
  text-transform: uppercase;
  margin-bottom: var(--margin-xs);
  font-weight: 600;

  @include media(tablet) {
    margin-bottom: 12px;
  }

  @include media(desktopAll) {
    margin-bottom: 12px;
  }
}

.title {
  display: inline-block;
  font-family: 'KharkivTone', sans-serif;
  font-size: 24px;
  line-height: 32px;

  @include media(desktopAll) {
    font-size: 32px;
    line-height: 40px;
  }
}

.description {
  padding-top: 8px;
  font-size: 14px;
  line-height: 2;
  font-weight: 300;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  @include media(desktopAll) {
    padding-top: 16px;
    font-size: 16px;
  }
}

.technologies {

  &__title {
    @include setProperty(color, var(--secondary-100), var(--secondary-600));
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--margin-md);
  }

  .technology {
    flex-shrink: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
    }
  }
}

.viewButton {
  display: inline-block;
  cursor: pointer;
  margin-left: -24px;
  font-size: 16px;
  line-height: 1;
  font-family: 'KharkivTone', sans-serif;

  svg {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
  
  .buttonContent {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}