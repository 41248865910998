@import 'src/styles/helpers';

.headSection {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 56px 0 64px;

    @include media(portrait) {
        padding-bottom: 104px;
    }

    @include media(landscape) {
        padding-bottom: 120px;
    }

    @include media(desktopAll) {
        padding-bottom: 120px;
    }

    .title {
        font-size: 36px;
        line-height: 44px;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));

        @include media(desktopAll) {
            font-size: 48px;
            line-height: 56px;
            letter-spacing: 1px;
        }

        mark {
            @include setProperty(color, var(--primary-500), var(--primary-900));
        }
    }
}

.searchBlock {
    display: flex;
    flex-direction: column;
    gap: 18px;

    @include media(desktopAll) {
        margin-top: 16px;
    }

    .inputWrapper {
        position: relative;

        .searchInput {
            background: none;
            border: none;
            font-size: 18px;
            line-height: 32px;
            letter-spacing: 0.4px;
            padding: 0 0 12px 24px;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
            caret-color: var(--primary-500);
            border-bottom: 1px solid var(--secondary-200);
            width: 100%;

            ::placeholder {
                color: var(--secondary-200);
                font-weight: 300;
                opacity: 0.6;
            }

            &:focus-visible {
                @include setProperty(border-color, var(--secondary-50), var(--secondary-500));
                outline: none;
            }
        }

        .searchIcon {
            width: 22px;
            height: 22px;
            position: absolute;
            right: 40px;
            top: 6px;
        }

        .clearButton {
            position: absolute;
            right: 40px;
            top: 6px;
            width: fit-content;
            height: fit-content;
            padding: 0;

            .clearIcon {
                width: 22px;
                height: 22px;
                cursor: pointer;

                @include setProperty(color, var(--secondary-200), var(--secondary-500));
            }

            &:hover {
                .clearIcon {
                    @include setProperty(color, var(--primary-500), var(--primary-900));
                }
            }
        }
    }
}

.popularSearched {
    display: flex;
    gap: 8px;
    overflow: hidden;
    flex-wrap: wrap;

    .popularSearchItem {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.183px;
        text-transform: capitalize;
        padding: 12px 24px;
        border-radius: 60px;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
        @include setProperty(background-color, var(--secondary-800), var(--secondary-50));
    }
}

.bannerArticle {
    display: flex;
    flex-direction: column;

    @include media(landscape) {
        flex-direction: row;
    }

    @include media(desktopAll) {
        flex-direction: row;
    }

    .bannerTitle {
        font-family: 'KharkivTone', sans-serif;
        font-size: 28px;
        line-height: 36px;
        
        @include media(desktopAll) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    .articleDescription {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        @include setProperty(background-color, var(--secondary-700), var(--secondary-50));

        @include media(landscape) {
            padding: 32px;
            justify-content: space-between;
            max-width: 50%;
        }

        @include media(desktopAll) {
            padding: 32px;
            justify-content: space-between;
            max-width: 50%;
        }

        .descriptionHead {
            display: flex;
            flex-direction: column;
            gap: 24px;

            @include media(landscape) {
                gap: 32px;
            }
        }

        .articleCategory {
            font-family: 'KharkivTone' sans-serif;
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            text-transform: lowercase;
            @include setProperty(color, var(--primary-500), var(--primary-900));

            &::before {
                content: '.';
            }

            &:hover {
                text-decoration: underline;
                @include setProperty(color, var(--primary-300), var(--primary-700));
            }

            &:active {
                @include setProperty(color, var(--primary-200), var(--primary-500));
                text-decoration: underline;
            }
        }

        .articleTitle {
            font-family: 'KharkivTone' sans-serif;
            font-size: 28px;
            line-height: 36px;
            font-weight: 400;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));

            mark {
                color: var(--primary-500);
            }

            &:hover {
                text-decoration: underline;
            }

            @include media(desktopAll) {
                font-size: 32px;
                line-height: 40px;
            }
        }
    }

    .imageWrapper {
        width: 100%;
        overflow: hidden;

        .bannerImage {
            width: 100%;
            height: 100%;
            object-fit: cover;

            transition: transform ease-in-out 0.3s;

            &:hover {
                transform: scale(1.05);
            }
        }

        @include media(landscape) {
            max-width: 50%;
        }

        @include media(desktopAll) {
            max-width: 50%;
        }
    }

    
}